/**
 * Dynamisation du sommaire des articles
 * @author Sandrine M'zé
 * par artwaï pour artwaï
 *
 */

const summary = {

    postTitles: [], // Eléments h2 dans l'article
    domElement: '', // Element ol du sommaire
    entries: [], // Eléments a du sommaire

    init() {
        this.postTitles = document.querySelectorAll('.content-post h2');
        if (this.postTitles.length > 0) {
            this.generate();
        }
    },

    /**
     * Génération du HTML du sommaire en fonction des h2 de l'article
     */
    generate() {
        let summaryHTML = "";
        this.domElement = document.querySelector('#summary');

        if (this.domElement) {
            for (const [index, element] of this.postTitles.entries()) {

                const textToId = this.createIdFromTitle(element.innerText); // Cas à gérer : https://artw4i.prev.artwai.fr/no-fucking-js-spirit/ | Fred utilise une image comme h2 ! MERCI FRED
                element.id = textToId;

                // Créé les éléments li dans le sommaire
                const newLi = document.createElement('li');
                const newAnchor = document.createElement('a');

                newAnchor.href = '#' + textToId;
                newAnchor.dataset.id = textToId;
                newAnchor.textContent = (index + 1) + ' - ' + element.innerText;
                newLi.appendChild(newAnchor);

                summaryHTML += newLi.outerHTML;
            }

            this.domElement.innerHTML = summaryHTML;
            this.entries = [...this.domElement.querySelectorAll('a')];
        }

        const opts = {
            firstElementActive: true,
            intersectionObserver: {
                root: null,
                rootMargin: "0px 0px -80% 0px", // Option d'intersection à 80% du bas du viewport
            },
        };

        handlePageScroll.init({
            observer: this.entries,
            observerTarget: this.postTitles,
        }, false, opts);
    },

    /**
     * Création d'un id à partir du h2
     * @param {string} text - h2 de l'article à partir duquel on crée un id pour l'ancre
     * @returns string
     */
    createIdFromTitle(text) {
        return text
            // Remplace les espaces par des tirets et les apostrophes par des tirets
            .replace(/[ '’]/g, '-')

            // Supprime les caractères de ponctuation à la fin de l'identifiant
            .replace(/[^\w\s-]+$/, '')

            // Supprime un éventuel tiret à la fin si le dernier caractère est une ponctuation
            .replace(/-$/, '')
            .toLowerCase();
    }
}

document.addEventListener('DOMContentLoaded', function() {
    summary.init();
});